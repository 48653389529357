import React from "react";
import "./Info.scss"
import clock from "./../../Images/Info/clock.png"
import book from "./../../Images/Info/book.png"
import questions from "./../../Images/Info/questions.png"
import mentor from "./../../Images/Info/mentor.png"

const Info = () => {
    return (
        <div className="info">
            <div className="info__container container ">
                <div className="info__items">
                    <div className="info__item">
                        <img className="info__icon" src={book}/>
                        <p className="info__desc">Курс состоит из 15&nbsp;уроков</p>
                    </div>
                    <div className="info__item">
                        <img className="info__icon" src={questions}/>
                        <p className="info__desc">В&nbsp;конце уроков вопросы на&nbsp;закрепление материала</p>
                    </div>
                    <div className="info__item">
                        <img className="info__icon" src={mentor}/>
                        <p className="info__desc">У каждого участника курса будет куратор для&nbsp;ответов на&nbsp;вопросы</p>
                    </div>
                    <div className="info__item">
                        <img className="info__icon" src={clock}/>
                        <p className="info__desc">Каждый урок займет 15&nbsp;-&nbsp;20&nbsp;минут времени</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;
