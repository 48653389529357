import "./testimonials.css"

import SwiperSlider from "./SwiperSlider";

const Testimonials = () => {

    const testimonials = {
        testimonials: [
            { id: 1,
                name: "Любовь",
                description: "“Я поняла, что нужно больше верить и доверять Иисусу. Ведь только Он способен изменить нашу жизнь." +
                    "Наиболее полезными были вопросы. Иногда приходилось задуматься о том, о чём в обычной жизни я никогда не задумывалась. Спасибо." +
                    "Несмотря на то, что курс бесплатный, благодаря тому, что есть обратная связь мне хотелось сюда возвращаться. " +
                    "Самый сложный период был где-то в середине курса.. посещали мысли забросить. Но я держалась. Спасибо вам за поддержку!”"
            },
            { id: 2,
                name: "Александр",
                description: "“Курс очень понравился. Пришлось переосмыслить многие вещи. Выражаю благодарность всем, кто поучаствовал в создании этого курса.“"
            },
            { id: 3,
                name: "Вячеслав",
                description: "“Подкрепил свои знания. Очень хороший курс как для новичков, так и для опытных христиан. Понравились вопросы-ответы с наставником“"
            },
            { id: 4,
                name: "Наталья, 32 года, Новгород",
                description: "“Я много слышала имя Иисуса Христа, и даже праздновала праздник пасхи, но никогда не знала, почему Ему уделяется так много внимания и зачем Его распяли. Теперь знаю“"
            },
            { id: 5,
                name: "Владислав, 41 год, Москва",
                description: "“Всегда думал, что Библия это просто древние рукописи, и к нам не имеют особого отношения. Оказалось, что имеют. Пройдя курс изменил отношение“"
            },
            { id: 6,
                name: "Кристина, 27 лет, Новгород",
                description: "“Мне очень понравилось, что у нас в рамках курсе есть кураторы! Помогали с вопросами и по самому курсу и которые появлялись по теме христианства. Спасибо большое за такую готовность помогать!“"
            },

        ]
    }

    return (
        <section id="testimonials">
            <div className="testimonial__header">
                <h2>Что говорят студенты?</h2>
            </div>


            <div className="swiper__big">
                <SwiperSlider testimonials={testimonials} spaceBetween={40} slidesPerView={1}/>
            </div>

           <div className="swiper__middle">
                <SwiperSlider testimonials={testimonials}  spaceBetween={10} slidesPerView={1}/>
            </div>

            {/*<div className="swiper__small">*/}
            {/*    <SwiperSlider testimonials={testimonials}  spaceBetween={30} slidesPerView={2.5}/>*/}
            {/*</div>*/}

        </section>
    )
}

export default Testimonials;