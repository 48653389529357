import React from "react"
import './stages.css'
import {GiOpenBook} from "react-icons/gi"
import {HiAcademicCap} from "react-icons/hi"
import {BiLinkExternal} from "react-icons/bi"

const Stages = () => {
    return (
        <section id="stages">
            <div className="header__text__stage">
                <h2>Как начать обучение?</h2>
            </div>

            <div className="container stages__container">
                <div className="stages__cards">

                    <article className="stages__card">
                        <div className="stages__content">
                            <BiLinkExternal className="stages__icon"/>
                            <h5>Пройдите по ссылке</h5>
                            <small>
                                Ознакомьтесь с кратким описанием курса и настройте себя на прохождение уроков
                            </small>
                            <a href="https://lifes-meaning.learnnn.com/basicsbible">перейти к курсу</a>
                        </div>
                    </article>

                    <article className="stages__card">
                        <div className="stages__content">
                            <GiOpenBook className="stages__icon"/>
                            <h5>Начните обучение </h5>
                            <small>
                                Проходите урок за уроком, отслеживайте свой прогресс,
                                открывайте для себя Библию, получайте ответы на свои вопросы.
                            </small>
                        </div>
                    </article>

                    <article className="stages__card">
                        <div className="stages__content">
                            <HiAcademicCap className="stages__icon"/>
                            <h5>Получи сертификат</h5>
                            <small>
                                После прохождения курса получите сертификат об окончании с подписью наставника
                            </small>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    )
}

export default Stages
