import React from "react";
import "./about.css"

const About = () => {
    return (
        <section id="about">
            <div className="container about__container">

                <div className="about__content__text">
                    <div className="about__text">
                        <div className="about__header__text"><h2>О курсе</h2></div>
                        <p>
                            <b>Данный курс - это краткое изложение всех основных событий и тем Библии. </b>
                            Материал подобран и составлен так, чтобы вы узнали все самое важное за
                            несколько часов и все было понятно каждому.
                        </p>
                    </div>
                    <div className="about__video">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kib1V9PssKo" frameBorder="0"
                                loading="lazy"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen=""></iframe>
                        {/*<p>*/}
                        {/*    Курс состоит из пятнадцати уроков. <b> Каждый урок займет 15 - 25 минут времени. </b>*/}
                        {/*    В конце уроков вопросы на закрепление материала.*/}
                        {/*    <b>У каждого участника курса будет куратор</b> для ответов на вопросы.*/}
                        {/*</p>*/}
                    </div>
                </div>

            </div>
        </section>
    );
}

export default About;
